import React from 'react';
import NameGrid from './components/NameGrid';

function App() {
  return (
    <main>
      <NameGrid />
    </main>
  );
}

export default App;
